// Customer.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import StackedHistoricBarChart from "../components/StackedHistoricBarChart";
import "../styles/Home.css";
import DiDataTable from "../components/DiDataTable";
import authFetch from "../services/NetworkUtils"
import "../styles/Manufacturer.css";

const Manufacturer = () => {
  const { param1 } = useParams();
  const config = require("../configure.json").API;
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const [di, setDI] = useState(null);
  const [diDailyDataManu, setDailyDIManu] = useState(null);
  const [modelBreakdown, setModelBreakdown] = useState(null);
  const [modelInstalls, setModelInstalls] = useState(null);
  const [modelIssues, setModelIssues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTrial, setShowTrial] = useState(false);
  const [totalInstalls, setTotalInstalls] = useState(0);
  const [totalIssues, setTotalIssues] = useState(0);
  const [showIssuesFromSoldCars, setShowIssuesFromSoldCars] = useState(false);
  const [statsUpdatedTime, setStatsUpdatedTime] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("showIssuesFromSoldCars") === "true") {
      setShowIssuesFromSoldCars(true);
    } else {
      setShowIssuesFromSoldCars(false);
    }

    setIsLoading(true);
    Promise.all([
      authFetch(`${config.api_di}?manufacturer=${param1}&include_trial=${showTrial}&include_ok_status=false`).then((response) =>
        response.data
      ),
      authFetch(`${config.api_daily_di_manu}?manufacturer=${param1}&include_trial=${showTrial}&include_ok_status=false`).then(
        (response) => response.data
      ),
    ]).then(
      ([
        api_di,
        api_daily_di_manu
      ]) => {
        setIsLoading(false);
        setDI(api_di);
        setDailyDIManu(api_daily_di_manu);
        var latestUpdateTime = null;
        var ts = null;
        api_di.forEach((item) => {
          const { timestamp } = item;
          ts = new Date(timestamp);
          if (latestUpdateTime == null) {
            latestUpdateTime = ts;
          } else {
            if (latestUpdateTime < ts) {
              latestUpdateTime = ts;
            }
          }
        });
        if (ts) {
          setStatsUpdatedTime(ts.toTimeString());
        }
        var model_set = new Set();
        var model_list = [];
        var model_total_installs = [];
        var model_issues = [];
        var thirtyDaysAgo = new Date().getTime() - (30 * 24 * 60 * 60 * 1000)
        var latestTsTime = null;
        var localTotalInstalls = 0;
        var localTotalIssues = 0;
        api_daily_di_manu.forEach((item) => {
          const { model_type, timestamp } = item;
          ts = new Date(timestamp);
          if (ts > thirtyDaysAgo) {
            if (!model_set.has(model_type)) {
              model_list[model_type] = [];
              model_set.add(model_type);
            }
          }
          if (model_type in model_list) {
            model_list[model_type].push(item);
          }
          const item_issues = (showIssuesFromSoldCars) ? (item.total_count) : (item.total_count_excl_sold);
          if (latestTsTime == null) {
            latestTsTime = ts;
            localTotalInstalls = item.total_installs;
            model_total_installs = [];
            model_issues = [];
            addToModelCounts(model_type, model_total_installs, item.total_installs, model_issues, item_issues);
            localTotalIssues = item_issues;
          } else {
            if (latestTsTime < ts) {
              latestTsTime = ts;
              localTotalInstalls = item.total_installs;
              model_total_installs = [];
              model_issues = [];
              addToModelCounts(model_type, model_total_installs, item.total_installs, model_issues, item_issues);
              localTotalIssues = item_issues;
            } else {
              localTotalInstalls = localTotalInstalls + item.total_installs;
              addToModelCounts(model_type, model_total_installs, item.total_installs, model_issues, item_issues);
              localTotalIssues = localTotalIssues + item_issues;
            }
          }
        });
        setTotalInstalls(localTotalInstalls);
        setTotalIssues(localTotalIssues);
        setModelBreakdown(model_list);
        setModelInstalls(model_total_installs);
        setModelIssues(model_issues);
      }
    );
  }, [
    config.api_di,
    config.api_daily_di_manu,
    param1,
    showTrial,
    showIssuesFromSoldCars,
    totalInstalls,
    totalIssues
  ]);

  const addToModelCounts = (model, model_total_installs, installs, model_issues, issues) => {
    if (!(model in model_total_installs)) {
      model_total_installs[model] = 0;
    }
    if (!(model in model_issues)) {
      model_issues[model] = 0;
    }
    model_total_installs[model] += installs;
    model_issues[model] += issues;
  };

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleSoldCarsSwitchChange = (checked) => {
    setShowIssuesFromSoldCars(checked);
    localStorage.setItem("showIssuesFromSoldCars", checked)
  };

  const handleTrialSwitchChange = (checked) => {
    setShowTrial(checked);
    localStorage.setItem("showTrial", checked)
  };

  function percentAsString(a, b) {
    if (b === 0) {
      return "";
    }
    return ((a / b) * 100).toFixed(2) + "%";
  }

  function getMainChartTitle() {
    const percent = percentAsString(totalIssues, totalInstalls);
    return "All Models - Currently " + totalInstalls + " Installed, with " + totalIssues + " Issues (" + percent + ")";
  }

  function getModelChartTitle(model) {
    const modelTotalInstalls = modelInstalls[model];
    const modelTotalIssues = modelIssues[model];
    const percent = percentAsString(modelTotalIssues, modelTotalInstalls);
    return model + " - Currently " + modelTotalInstalls + " Installed, with " + modelTotalIssues + " Issues (" + percent + ")";
  }

  function sort_object(obj) {
    var keyValues = []
    for (var key in obj) {
      keyValues.push([ key, obj[key] ])
    }
    return keyValues.sort(function compare(kv1, kv2) {
      // This comparison function has 3 return cases:
      // - Negative number: kv1 should be placed BEFORE kv2
      // - Positive number: kv1 should be placed AFTER kv2
      // - Zero: they are equal, any order is ok between these 2 items
      return kv2[1] - kv1[1]
    });
  }

  function renderModels() {
      if (modelBreakdown) {
         const sortedModelInstalls = sort_object(modelInstalls);
         return (
             <div className="item-list">
              {
                sortedModelInstalls.map(([key, value], index) => (
                    <div key={key} className="chart-flexbox">
                     <StackedHistoricBarChart
                       chartTitle={getModelChartTitle(key)}
                       stackedBarChartApi={modelBreakdown[key]}
                       defaultTimeframe="1 month"
                       aggregateOn="issue_type"
                       inclSold={showIssuesFromSoldCars}
                       style={{
                         width: "98%",
                         marginBottom: "1%",
                         paddingTop: "40px",
                       }}
                     />
                   </div>
               ))
              }
              </div>
      )}
  }


  return (
    <>
    <div className="fade-cust-in">

    <div style={{ overflowX: "auto" }}>

      <div className="manufacturer-container">

        <div className="user-controls-container-flexbox">

          {/* User controls */}
          <div>
            <div className="user-controls-flexbox">
              <div className="user-controls-element">
                <div>
                  <Switch onChange={handleSoldCarsSwitchChange} checked={showIssuesFromSoldCars} />
                </div>
                {showIssuesFromSoldCars === false ? (<div>Hiding Issues from SOLD vehicles</div>) : (<div>Showing Issues from SOLD vehicles</div>) }
              </div>
              <div className="user-controls-element">
                <div>
                  <Switch onChange={handleTrialSwitchChange} checked={showTrial} />
                </div>
                {showTrial === false ? (<div>Hiding Issues From Trial</div>) : (<div>Showing Issues From Trial</div>) }
              </div>
              <div className="user-controls-element">
                {isLoading ? (
                  <div className="loading-icon"></div>
                ) : (
                  <div>
                    <div>Stats Updated:</div>
                    <div>{statsUpdatedTime}</div>
                  </div>
                )}
              </div>
              <div className="user-controls-element">
                <div>
                  <div>Manufacturer: {param1}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-flexbox">

          <div className="number-flexbox">
            <div className="data-container" style={{width: "32%"}}>
              <p className="number-value">
                <span id="totalInstalledSum" style={{color: "rgb(75, 192, 192)"}}> {formatNumberWithCommas(totalInstalls)} </span>
              </p>
              <h6 className="total-headers">Installed Devices</h6>
            </div>

            <div className="data-container" style={{width: "32%"}}>
              <p className="number-value">
                <span id="totalInstalledSum" style={{color: "#e61e10"}}> {formatNumberWithCommas(totalIssues)} </span>
              </p>
              <h6 className="total-headers">Issues</h6>
            </div>

            <div className="data-container" style={{width: "32%"}}>
              <p className="number-value">
                <span id="totalInstalledSum" style={{color: "#e61e10"}}> {percentAsString(totalIssues, totalInstalls)} </span>
              </p>
              <h6 className="total-headers">Percent of Installed</h6>
            </div>

          </div>


          <div className="chart-flexbox">
            <StackedHistoricBarChart
                chartTitle={getMainChartTitle()}
                defaultTimeframe="1 month"
                aggregateOn="issue_type"
                stackedBarChartApi={diDailyDataManu}
                inclSold={showIssuesFromSoldCars}
                style={{
                    width: "98%",
                    marginBottom: "1%",
                    paddingTop: "40px",
                  }}
              />
            </div>

            {renderModels()}

          </div>

          <div id="btn-table">
            <h4 className="table-name ">
              Device Issues
            </h4>
          </div>

          <div className="main-table">
            <DiDataTable apiData={di}/>
          </div>
        </div>
      </div>
    </div>
    </>
      );
      };

      export default Manufacturer;
