import download from "../images/download.png";
const XLSX = require("xlsx");

const ExcelCurrentStockIssuesDownloadButton = ({ stats, inclNotTrackedStatus }) => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}`;
  var file_type = "StockIssues";
  const fileName = `${file_type}_${formattedDate}.xlsx`;

  function mapObjectCounts(dayData) {
      var newData = {};
      for (const key in dayData) {
          if (key === "customer_identifier") {
              newData[key] = dayData[key];
              continue;
          } else if (key === "timestamp") {
              continue;
          } else if (!inclNotTrackedStatus && key.startsWith("Vehicle not tracked")) {
              // Skip these entries
              continue
          } else {
              newData[key] = dayData[key];
          }
      }
      return newData;
  }

  const handleDownload = () => {
    if (!stats) {
      console.error("Data is not available.");
      return;
    }

    const counts = stats.map((dayData) => mapObjectCounts(dayData));

    const statsSheet = XLSX.utils.json_to_sheet(counts);

    const sheetName = "StockIssues"

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      statsSheet,
      sheetName
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "data.xlsx"; // Use the provided fileName or fallback to "data.xlsx"
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <span id="download-btn">
      <button onClick={handleDownload} title={"Download Data"}>
        <img src={download} alt="download" />
      </button>
    </span>
  );
};

export default ExcelCurrentStockIssuesDownloadButton;
