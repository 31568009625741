import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "../styles/Charts.css";

const LineChartByManufacturer = ({ lineChartApi, chartTitle, style, inclSold, showTrial }) => {
  const chartRef = useRef(null);
  const [timeframe, setTimeframe] = useState("1 month");

  // line chart total installs
  useEffect(() => {
    let chartInstance = null;

    function itemAsDate(item) {
      var i;
      if ('date' in item) {
        i = item.date;
      } else {
        i = item.timestamp;
      }
      return new Date(i);
    }

    if (lineChartApi) {

      let filteredData = lineChartApi;
      if (timeframe === "max") {
        filteredData = lineChartApi.filter(
          (entry) => itemAsDate(entry) >= new Date("2023-08-16")
        );
      } else if (timeframe === "1 year") {
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        filteredData = lineChartApi.filter(
          (entry) => itemAsDate(entry) >= oneYearAgo
        );
      } else if (timeframe === "1 month") {
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        filteredData = lineChartApi.filter(
          (entry) => itemAsDate(entry) >= oneMonthAgo
        );
      } else if (timeframe === "1 week") {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        filteredData = lineChartApi.filter(
          (entry) => itemAsDate(entry) >= oneWeekAgo
        );
      }

      const canvas = chartRef.current;
      const ctx = canvas.getContext("2d");

      const labels = [];

      const teltonikaIssues = [];
      const queclinkIssues = [];
      const metaIssues = [];

      const aggregatedData = {};

      filteredData.forEach((item) => {
        const date = new Date(item.timestamp);
        //const formattedDate = date.toLocaleDateString("en-IE");
        const formattedDate = date.toISOString().split('T')[0];
        var teltonikaCount = 0
        var queclinkCount = 0
        var metaCount = 0
        for (const key in item) {
         if (key !== "timestamp" && key !== "customer_identifier") {
           const model_details = item[key];
           const manufacturer = model_details["manufacturer"];
           if (manufacturer === "Teltonika") {
             teltonikaCount += model_details["count_excl_sold"];
           } else if (manufacturer === "Queclink") {
             queclinkCount += model_details["count_excl_sold"];
           } else if (manufacturer === "MetaSystem") {
             metaCount += model_details["count_excl_sold"];
           }
         }
        };
        if (!(formattedDate in aggregatedData)) {
          aggregatedData[formattedDate] = {
            teltonikaCount: 0,
            queclinkCount: 0,
            metaCount: 0
          }
        }
        aggregatedData[formattedDate]["teltonikaCount"] +=  teltonikaCount;
        aggregatedData[formattedDate]["queclinkCount"] +=  queclinkCount;
        aggregatedData[formattedDate]["metaCount"] +=  metaCount;
      });

      for (const formattedDate in aggregatedData) {
        labels.push(formattedDate);
        const counts = aggregatedData[formattedDate];
        teltonikaIssues.push(counts.teltonikaCount);
        queclinkIssues.push(counts.queclinkCount);
        metaIssues.push(counts.metaCount);
      }

      var datasets = [

          {
            data: teltonikaIssues,
            label: "Teltonika",
            fill: true,
            backgroundColor: "rgba(30,93,219,0.1)",
            borderColor: "rgba(30,93,219,1)",
            pointBackgroundColor: Array(teltonikaIssues.length).fill(
              "rgba(30,93,219,1)"
            ),
            yAxisID: "y1",
          },
          {
            data: queclinkIssues,
            label: "Queclink",
            fill: true,
            backgroundColor: "rgb(166,0,45, 0.1)",
            borderColor: "rgb(166,0,45)",
            pointBackgroundColor: Array(queclinkIssues.length).fill(
              "rgb(166,0,45)"
            ),
            yAxisID: "y1",
          },
          {
            data: metaIssues,
            label: "MetaSystem",
            fill: true,
            backgroundColor: "rgba(245, 135, 66, 0.1)",
            borderColor: "rgba(245, 135, 66, 1)",
            pointBackgroundColor: Array(metaIssues.length).fill(
              "rgba(245, 135, 66, 1)"
            ),
            yAxisID: "y1",
          },
        ];

      const chartData = {
        labels: labels,
        datasets: datasets,
      };

      let total = 0;
      if (chartInstance) {
        chartInstance.data = chartData;
        chartInstance.update();
      } else {
        chartInstance = new Chart(ctx, {
          type: "line",
          data: chartData,
          options: {
            aspectRatio: 5,
            maintainAspectRatio: true,
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                display: true,
                align: "center", // Align the labels to the right
                fullWidth: false,
              },
              title: {
                display: (chartTitle)?true:false,
                text: chartTitle,
                color: "#009879",
                font: {
                  size: 16,
                },
                padding: {
                  top: 15,
                  bottom: 15,
                },
              },
              tooltip: {
                mode: "index",
                intersect: false,
                callbacks: {
                  beforeBody: () => {
                    total = 0;
                  },
                  label: function (context) {
                    const datasetLabel = context.dataset.label || "";
                    const value = context.parsed.y;
                    total += value;
                    return `${datasetLabel}: ${value}`;
                  },
                  afterBody: () => {
                    return `Total: ${total || 0}`;
                  },
                },
              },
            },
            hover: {
              mode: "index",
              intersect: false,
            },

            scales: {
              y: {
                type: "linear",
                display: "auto",
                position: "left",
              },
              y1: {
                type: "linear",
                display: "auto",
                position: "right",
              },
            },
          },
        });
      }
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [lineChartApi, chartTitle, timeframe, inclSold, showTrial]);

  // Function to handle button click and update the selected timeframe
  const handleTimeframeChange = (selectedTimeframe) => {
    setTimeframe(selectedTimeframe);
  };

  return (
    <div
      className="linegraph-container"
      style={{ ...style, position: "relative" }}
    >
      <canvas ref={chartRef} id="lineChartDevices" />
      <div
        className="timeframe-buttons"
        style={{ position: "absolute", top: "4px", left: "10px" }}
      >
        <button
          className={timeframe === "max" ? "selected" : ""}
          onClick={() => handleTimeframeChange("max")}
        >
          Max
        </button>
        <button
          className={timeframe === "1 year" ? "selected" : ""}
          onClick={() => handleTimeframeChange("1 year")}
        >
          1Y
        </button>
        <button
          className={timeframe === "1 month" ? "selected" : ""}
          onClick={() => handleTimeframeChange("1 month")}
        >
          1M
        </button>
        <button
          className={timeframe === "1 week" ? "selected" : ""}
          onClick={() => handleTimeframeChange("1 week")}
        >
          1W
        </button>
      </div>
    </div>
  );
};

export default LineChartByManufacturer;
